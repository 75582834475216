// 创作
const write = {
	first: {
		url: "/addons/chatgpt/write/topic",
		method: "GET",
		desc: "一级分类"
	},
	second: {
		url: "/addons/chatgpt/write/prompts",
		method: "GET",
		desc: "二级分类"
	},
	detail: {
		url: "/addons/chatgpt/write/prompt",
		method: "GET",
		desc: "分类详情"
	},
	collectList: {
		url: "/addons/chatgpt/write/vote_prompts",
		method: "GET",
		desc: "收藏的二级分类列表"
	},
	collect: {
		url: "/addons/chatgpt/write/vote_prompt",
		method: "GET",
		desc: "收藏/取消收藏二级分类"
	},
	record: {
		url: "/addons/chatgpt/web/history_write",
		method: "GET",
		desc: "创作记录"
	},
	delete: {
		url: "/addons/chatgpt/web/del_write_msg",
		method: "POST",
		desc: "删除创作记录"
	}
}

export default write
