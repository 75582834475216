// 充值
const recharge = {
	integralList: {
		url: "/addons/chatgpt/usable/index",
		method: "GET",
		desc: "积分类别"
	},
	memberList: {
		url: "/addons/chatgpt/vip/index",
		method: "GET",
		desc: "会员类别"
	},
	dealerList: {
		url: "/addons/chatgpt/reseller/index",
		method: "GET",
		desc: "经销商类别"
	},
	createIntegralOrder: {
		url: "/addons/chatgpt/usable/recharge",
		method: "POST",
		desc: "创建积分充值订单"
	},
	createMemberOrder: {
		url: "/addons/chatgpt/vip_order/recharge",
		method: "POST",
		desc: "创建会员订单"
	},
	createDealerOrder: {
		url: "/addons/chatgpt/reseller/recharge",
		method: "POST",
		desc: "创建经销商订单"
	},
	pay: {
		url: "/addons/chatgpt/pay/prepay",
		method: "POST",
		desc: "拉起支付"
	},
	queryPayStatus: {
		url: "/addons/chatgpt/pay/checkPay",
		method: "POST",
		desc: "查询支付状态"
	}
}

export default recharge
