// 角色
const role = {
	first: {
		url: "/addons/chatgpt/rule/topic",
		method: "GET",
		desc: "一级分类"
	},
	second: {
		url: "addons/chatgpt/rule/prompts",
		method: "GET",
		desc: "二级分类"
	},
	detail: {
		url: "/addons/chatgpt/rule/prompt",
		method: "GET",
		desc: "分类详情"
	},
	collectList: {
		url: "/addons/chatgpt/rule/vote_prompts",
		method: "GET",
		desc: "收藏的二级分类列表"
	},
	collect: {
		url: "/addons/chatgpt/rule/vote_prompt",
		method: "GET",
		desc: "收藏/取消收藏二级分类"
	},
	create: {
		url: "/addons/chatgpt/web/rule_group",
		method: "GET",
		desc: "创建角色会话"
	},
	history: {
		url: "/addons/chatgpt/web/history_rule",
		method: "GET",
		desc: "角色历史记录"
	}
}

export default role
