/**
 * 验证手机号
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkTelPhone = (rule, value, callback) => {
	!value && callback()
	const reg = /^1[3-9]\d{9}$/
	if (!reg.test(value)) {
		callback(new Error("手机号输入格式有误"))
	} else {
		callback()
	}
}

/**
 * 验证密码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const checkPassword = (rule, value, callback) => {
	!value && callback()
	const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])[\w!@#$%^&*()-+=<>?/\[\]{}|~]{6,20}$/
	if (!reg.test(value)) {
		callback("密码格式有误")
	} else {
		callback()
	}
}

/**
 * 比较两次密码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @param {*} params
 */
const checkAgainPassword = (rule, value, callback, params) => {
	!value && callback()
	if (params == value) {
		callback()
	} else {
		callback("两次密码不一致")
	}
}

export { checkTelPhone, checkPassword, checkAgainPassword }
