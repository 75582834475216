// 登录
const login = {
	wxLogin: {
		url: "/addons/chatgpt/user/qrcode",
		method: "GET",
		desc: "公众号登录"
	},
	wxVerify: {
		url: "/addons/chatgpt/user/verify",
		method: "GET",
		timeout: 1000 * 100,
		desc: "验证授权登录"
	},
	wxBind: {
		url: "/addons/chatgpt/user/register",
		method: "POST",
		desc: "绑定手机号"
	},
	logout: {
		url: "/addons/chatgpt/user/logout",
		method: "GET",
		desc: "退出登录"
	},
	sendCode: {
		url: "/addons/chatgpt/sms/send",
		method: "POST",
		desc: "发送验证码"
	},
	smsLogin: {
		url: "/addons/chatgpt/user/smsLogin",
		method: "POST",
		desc: "验证码登录/注册"
	},
	smsRegister: {
		url: "/addons/chatgpt/user/smsRegister",
		method: "POST",
		desc: "手机号注册"
	},
	accountLogin: {
		url: "/addons/chatgpt/user/accountLogin",
		method: "POST",
		desc: "账号密码登录"
	},
	forgotPassword: {
		url: "/addons/chatgpt/user/forgotPwd",
		method: "POST",
		desc: "找回密码"
	}
}

export default login
